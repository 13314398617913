export default {
  computed: {
    getUrlConnector () {
      return this.serializeToString().length === 0 ? '?' : '&'
    }
  },
  methods: {
    updateUrlProps (props) {
      this.urlQuery = Object.assign({}, this.$route.query)
      Object.keys(props).map(key => {
        this.urlQuery[key] = props[key]
      })
      this.$router.replace({ query: this.urlQuery })
    },
    updateFt (funeralType) {
      this.replaceUrl(this.getUrlParts()[2], funeralType)
    },
    getUrlProp (prop) {
      return (this.$route.query[prop] !== undefined) ? this.$route.query[prop] : false
    },
    replaceUrl (toReplace, newData) {
      this.$router.replace({ path: this.$route.path.replace(toReplace, newData), query: this.$route.query }).catch(() => {})
    },
    addToUrl (toAdd) {
      let slash = '/'
      if (this.$route.path[this.$route.path.length - 1] === '/') slash = ''
      this.replaceUrl(this.$route.path, this.$route.path + slash + toAdd)
    },
    serializeToString (obj = this.urlQuery) {
      let urlQuery = Object.assign({}, obj)
      var str = []
      for (var p in urlQuery) {
        if (urlQuery.hasOwnProperty(p)) {
          str.push(encodeURIComponent(p) + '=' + encodeURIComponent(urlQuery[p]))
        }
      }
      return str.join('&')
    },
    getUrlParts () {
      return this.$route.path.split('/')
    },
    getAddressString (string) {
      return this.capitalizeAllFirstLetters(string.replace(/-/g, ' '))
    }
  },
  beforeCreate () {
    this.urlQuery = Object.assign({}, this.$route.query)
  }
}
