<template>
  <div>
    <v-container grid-list-xl>
      <app-card>
        <v-chip
          v-if="$route.query.id != null"
          class="mb-2"
          close
          @input="removeId()">
          id: {{ $route.query.id }}
        </v-chip>
        <v-data-table
          :headers="headers"
          :items="items"
          :loading="loading"
          :pagination.sync="pagination"
          :total-items="totalItems"
          :rows-per-page-items="[10, 25, 100, 500]"
          class="elevation-1"
        >
          <template v-slot:items="props">
            <td>
              {{ props.item.id }}
            </td>
            <td>
              {{ props.item.entityField }}
            </td>
            <td>
              <div v-if="props.item.entityField === 'minHoursPerRide'">
                {{ props.item.oldValue.time }}
              </div>
              <div v-else>
                {{ props.item.oldValue }}
              </div>
            </td>
            <td>
              <div v-if="props.item.entityField === 'photo'">
                <img
                  :src="props.item.newValue.url"
                  width="70">
              </div>
              <div v-else-if="props.item.entityField === 'minHoursPerRide'">
                {{ props.item.newValue.time }}
              </div>
              <div v-else>
                {{ props.item.newValue }}
              </div>
            </td>
            <td>
              {{ props.item.status }}
            </td>
            <td>
              <v-btn
                outline
                flat
                small
                color="primary"
                @click="$router.push(`/tables/${props.item.entityName}s/${props.item.entity.id}`)">{{ props.item.entity.user.firstName }} {{ props.item.entity.user.lastName }} id: {{ props.item.entity.user.id }}</v-btn>
            </td>
            <td >
              <div
                v-if="props.item.status === 'pending'"
                class="row-layout">
                <v-btn
                  color="primary"
                  @click="approveChange(props.item)">Approve</v-btn>
                <v-btn
                  color="warning"
                  class="mr-3"
                  @click="rejectChange(props.item)"
                >Reject</v-btn>
              </div>
              <div/>
            </td>
          </template>
        </v-data-table>
      </app-card>
    </v-container>
  </div>
</template>

<script>
import request from '../../../helpers/request'
import urlController from '../../../helpers/urlController'

export default {
  mixins: [request, urlController],
  data () {
    return {
      headers: [
        { text: 'Id', value: 'id' },
        { text: 'Entity type', value: 'entityField' },
        { text: 'Old value', value: 'oldValue' },
        { text: 'New value', value: 'newValue' },
        { text: 'Status', value: 'status' },
        { text: 'User', value: 'entity', textValue: 'user' },
        { text: '', value: '', textValue: '' }
      ],
      items: [],
      pagination: {
        rowsPerPage: 100,
        page: 1
      },
      totalItems: 0,
      loading: false
    }
  },
  watch: {
    pagination: {
      deep: true,
      handler () {
        this.requestData()
      }
    }
  },
  beforeMount () {
    this.requestData()
  },
  methods: {
    requestData () {
      let body = {
        params: {
          pageSize: this.pagination.rowsPerPage,
          page: this.pagination.page,
          id: this.$route.query.id
        }
      }
      this.request('GET', `/audit`, body, ({ data }) => {
        this.items = data.data
        this.totalItems = data.total
      }, (loader) => { this.loading = loader })
    },
    approveChange (item) {
      this.request('POST', `/audit/approve/${item.id}`, {}, ({ data }) => {
        this.requestData()
        this.$notify({
          group: 'topRight',
          type: 'success',
          text: 'Change has been approved'
        })
      })
    },
    rejectChange (item) {
      this.request('POST', `/audit/reject/${item.id}`, {}, ({ data }) => {
        this.requestData()
        this.$notify({
          group: 'topRight',
          type: 'warning',
          text: 'Change has been rejected'
        })
      })
    },
    removeId () {
      this.updateUrlProps({ id: undefined })
      this.requestData()
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
